/* eslint-disable prefer-named-capture-group */
/* eslint-disable require-unicode-regexp */
/** @jsx jsx */
import { jsx, Box, Flex, SxStyleProp, Text } from 'theme-ui'
import React, { FC, useEffect, useRef, useState } from 'react'
import { navigate, Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { Button } from '~/components/button'
import { string, object, boolean } from 'yup'
import { Formik, Form } from 'formik'
import useFormSubmission from '~/hooks/use-form-submission'
import useShopifyClient from '~/hooks/use-shopify-client'
import urls from '~/common/urls'
import ErrorFocus from '~/components/error-focus'
import ErrorMessage from '~/components/error-message'
import FormField from '~/components/form-field'
import { space } from '~/gatsby-plugin-theme-ui'
import { trackCompleteRegistration } from '~/utils/marketing/track'
import { base64DecodeShopifyId } from '~/utils/format'

import ReCAPTCHA from 'react-google-recaptcha'
import { WrapperRecaptcha } from './styles'

type Props = {
  sx?: SxStyleProp
}

type FormValues = {
  firstName: string
  lastName: string
  email: string
  password: string
  acceptsMarketing: boolean
}

const initialValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  acceptsMarketing: false,
}

const nameSchema = (field?: string) =>
  string()
    .matches(/^[a-zA-Z]+$/, `${field} must be a valid name`)
    .matches(/^(?!.*(test|tester|tests|null)).*$/, `${field} must be a valid name`)
    .required(`${field} is required`)
    .trim(`Please enter a valid ${field}`)

const schema = object().shape({
  firstName: nameSchema('First name'),
  lastName: nameSchema('Last name'),
  email: string().email().required(),
  password: string()
    .matches(
      /(?=(.*[0-9]))(?=.*[@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?!])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/u,
      {
        message:
          '1 lowercase letter, 1 uppercase letter, 1 number, 1 special character and be at least 8 characters long',
      }
    )
    .required(),
  acceptsMarketing: boolean(),
})

const SignUp: FC<Props> = ({ sx = {} }) => {
  const { client } = useShopifyClient()
  const { pathname } = useLocation()
  const reCaptchaElement: any = useRef<any>(null)
  const [recaptchaError, setRecaptchaError] = useState<string | null>(null)

  const signUpCallback = async (values: FormValues): Promise<any> => {
    const response = await client.signUp({
      email: values.email,
      password: values.password,
      acceptsMarketing: values.acceptsMarketing,
      firstName: values.firstName,
      lastName: values.lastName,
    })
    const { id } = response

    trackCompleteRegistration({
      userId: base64DecodeShopifyId(id) as number,
      pageName: pathname,
    })

    navigate(urls.account.signIn)
  }
  const formRef = React.useRef<HTMLFormElement>(null)

  const { onSubmit, isLoading, serverError } = useFormSubmission<FormValues, any>(
    signUpCallback as any
  )

  return (
    <Box
      as="div"
      sx={{
        ...sx,
        label: {
          textTransform: 'uppercase',
        },
      }}
    >
      <Formik
        ref={formRef}
        onSubmit={(...args) => {
          if (reCaptchaElement?.current?.getValue().length === 0) {
            setRecaptchaError('Please complete the reCAPTCHA')
            reCaptchaElement?.current?.error()
            return
          }
          onSubmit(...args)
        }}
        initialValues={initialValues}
        validationSchema={schema}
      >
        <Form data-cy="sign-up-form">
          <ErrorFocus />
          <Flex
            sx={{
              flexWrap: 'wrap',
            }}
          >
            <Box sx={{ flex: ['auto', '1 1 auto'], mb: [21], pr: [0, 17] }}>
              <FormField name="firstName" label="First Name" data-cy="firstName" />
            </Box>
            <Box sx={{ flex: ['auto', '1 1 auto'], mb: [21], pl: [0, 17] }}>
              <FormField name="lastName" label="Last Name" data-cy="lastName" />
            </Box>
          </Flex>
          <Flex
            sx={{
              mb: [21],
            }}
          >
            <Box sx={{ flex: '1' }}>
              <FormField name="email" label="Email Address" data-cy="email" />
            </Box>
          </Flex>
          <Flex
            sx={{
              mb: [21],
            }}
          >
            <Box sx={{ flex: '1' }}>
              <FormField name="password" type="password" label="Password" data-cy="password" />
            </Box>
          </Flex>
          <WrapperRecaptcha hasError={!!recaptchaError}>
            <ReCAPTCHA
              ref={reCaptchaElement}
              onChange={() => {
                setRecaptchaError(null)
              }}
              sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY as string}
            />
            {recaptchaError && <ErrorMessage error={recaptchaError} data-cy="recaptcha-error" />}
          </WrapperRecaptcha>
          <Flex
            as={Box}
            sx={{ flex: '1', mt: [40, null, 82], mb: [52, null, 49], flexDirection: 'column' }}
          >
            <Button
              label={isLoading ? '...' : 'Create Account'}
              disabled={isLoading}
              sx={{
                width: '100%',
              }}
              type="submit"
            />
            {serverError && <ErrorMessage error={serverError} data-cy="server-error" />}
          </Flex>

          <Flex
            sx={{
              mb: [48],
            }}
          >
            <Box>
              <FormField
                as="checkbox"
                name="acceptsMarketing"
                label={
                  <React.Fragment>
                    I would like to receive the newsletter with the latest news and promotions.
                    Consult our <Link to={urls.pages.privacyPolicy}>Privacy Policy</Link> for
                    further information.
                  </React.Fragment>
                }
              />
            </Box>
          </Flex>
          <Text
            sx={{
              textAlign: 'center',
              fontSize: [18],
              textTransform: 'uppercase',
              display: 'block',
              width: '100%',
              color: 'txt',
              a: {
                display: ['block', null, 'inline-block'],
                mt: [space.md1, null, '0'],
              },
            }}
          >
            Already have an account?{' '}
            <Button
              as="link"
              to={urls.account.signIn}
              label="Login"
              variant="link"
              sxProps={{
                display: 'inline-flex',
                minWidth: 'auto',
                textDecoration: 'underline',
              }}
            />
          </Text>
        </Form>
      </Formik>
    </Box>
  )
}

export default SignUp
