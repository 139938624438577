import styled, { css } from 'styled-components'

interface IProps {
  hasError: boolean
}

export const WrapperRecaptcha = styled.div<IProps>`
  display: contents;

  iframe {
    ${({ hasError }) =>
      hasError &&
      css`
        border: 1px solid red;
      `}
  }
`
