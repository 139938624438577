/** @jsx jsx */
import { jsx, Container } from 'theme-ui'
import Layout from '~/components/layout'
import SignUpContainer from '~/containers/sign-up'
import HeadingV2 from '~/componentsV2/heading'

const SignUp = () => {
  const title = 'Create Account'
  return (
    <Layout headerVariant="default" footer={false} title={title}>
      <Container
        sx={{
          pt: [51, null, 108],
          pb: [40, null, 92],
          maxWidth: [576],
        }}
      >
        <HeadingV2
          sx={{
            textAlign: 'center',
            mb: [44, null, 60],
          }}
        >
          {title}
        </HeadingV2>
        <SignUpContainer />
      </Container>
    </Layout>
  )
}

export default SignUp
